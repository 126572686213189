<template>
  <div>
    <v-container>
      <v-row
        class="d-flex justify-center mt-16 bg_mode pa-3"
        style="border-left: 2px solid #2c6c70; border-right: 2px solid #2c6c70"
      >
        <h1 class="color-main">{{ langkeyword("CONTACT_US") }}</h1>
      </v-row>
    </v-container>

    <v-container>
      <v-row class="d-flex justify-center mt-10">
        <v-col cols="11" lg="8" md="10" class="bg_mode pa-8">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                outlined
                v-model="name"
                :label="this.langkeyword('NAME_CONTACT')"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="email"
                outlined
                :label="this.langkeyword('EMAIL_CONTACT')"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="subject"
                outlined
                :label="this.langkeyword('SUBJECT')"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea outlined v-model="detail">
                <template v-slot:label>
                  <div>
                    {{ langkeyword("BODY_CONTACT") }}
                  </div>
                </template>
              </v-textarea>
            </v-col>

            <v-col cols="12">
              <v-btn block color="primary" @click="sendcontact" :disabled="name==''|| subject==''|| email==''|| detail==''">
                {{ langkeyword("SEND") }}
                <v-icon class="mx-1" v-if="lang() === 'en'"
                  >mdi mdi-send</v-icon
                >
                <v-icon v-else class="mx-1" style="transform: rotate(180deg)"
                  >mdi mdi-send</v-icon
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <appFooter />
  </div>
</template>

<script>
import Vue from "vue"
import  axios  from 'axios';
import appFooter from "../../layouts/client/footer.vue";
export default {
  components: { appFooter },
  data() {
    return {
      name: "",
      subject: "",
      email: "",
      detail: "",
    };
  },
  methods: {

    sendcontact(){
      axios.post("/app/client/contact/send", {
        isSendContacts: true,
        name: this.name,
        subject: this.subject,
        email: this.email,
        msg: this.detail,
      })
      .then(() => {
        alert(Vue.prototype.langkeyword('SUCCESS'))
        this.name=null
        this.subject=null
        this.email=null
        this.detail=null
      })
      .catch((err) => {
        console.log("response error " + err);
      });

        },

  },
  mounted() {},
  coomputed: {},
};
</script>

<style lang="scss" scoped>
</style>