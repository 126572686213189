<template>
  <div>
    <!-- slider -->

    <slider></slider>










    <v-container class="mt-10 mb-10">
      <v-row class="d-flex justify-center">
        <h1 class="color_mode mb-2">{{ this.langkeyword("LATEST_PRODUCTS") }}</h1>
      </v-row>
      
      <v-row class="d-flex justify-center">
        <v-col
          v-for="(item, index) in products"
          :key="index"
          cols="6"
          lg="2"
          sm="4"
          md="3"
        >
          <router-link
            :to="'/products/' + item.id"
            style="text-decoration: none"
            class="color_mode"
          >
            <v-card
            outlined
                shaped
                elevation="5"
              class="mx-auto bg_mode text-center"
              max-width="400"
            >
              <v-img
                class="white--text mx-auto"
                width="100%"
                height="200"
                :src="uploadPath('/products/' + item.avatar)"
              >
              </v-img>
              <h2
                class="pb-0 px-2 mt-4 text-truncate"
                style="font-size: 20px; font-weight: 700 !important"
              >
                <span v-if="lang() === 'ku'">{{ item.ku_name }}</span>
                <span v-if="lang() === 'ar'">{{ item.ar_name }}</span>
                <span v-if="lang() === 'en'">{{ item.en_name }}</span>
              </h2>
              <v-card-subtitle class="pb-0" style="font-size: 16px">
                <span v-if="lang() === 'ku'">{{
                  item.productcategories_ku_name
                }}</span>
                <span v-if="lang() === 'ar'">{{
                  item.productcategories_ar_name
                }}</span>
                <span v-if="lang() === 'en'">{{
                  item.productcategories_en_name
                }}</span>
              </v-card-subtitle>

              <v-card-actions>
                <v-btn
                  :to="'/products/' + item.id"
                  text
                  class="mx-auto mb-3 mt-2 text-capitalize"
                  small
                >
                  <v-icon class="mx-1" color="#2c6c70"
                    >mdi mdi-chevron-right-circle</v-icon
                  ></v-btn
                >

                <div class="mb-2"></div>
              </v-card-actions>
            </v-card>
          </router-link>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center mt-10">
        <v-btn
          to="/products"
          color="#2c6c70"
          class="rounded-xl mx-auto mb-3 mt-2 text-capitalize"
          style="color: white"
          >{{ langkeyword("ALL_PRODUCTS") }}
          <v-icon class="mx-1">mdi mdi-chevron-right-circle</v-icon></v-btn
        >
      </v-row>
    </v-container>









<hr>









    <v-container class="mt-10 mb-10">
      <v-row class="d-flex justify-center">
        <h1 class="color_mode mb-2">{{ this.langkeyword("LATEST_BRANDS") }}</h1>
      </v-row>

      <v-row class="d-flex justify-center">
        <v-col
          v-for="(item, index) in brands"
          :key="index"
          cols="6"
          lg="2"
          sm="4"
          md="3"
        >
          <router-link
          :to="'/brands/' + item.id"
            style="text-decoration: none"
            class="white--text"
          >
            <v-card
              style="border-radius: 15px !important"
              elevation="4"
              class="mx-auto bg_main white--text text-center"
              max-width="400"
            >
              <v-img
                class="white--text mx-auto"
                width="100%"
                height="200"
                :src="uploadPath('/brands/' + item.avatar)"
              >
              </v-img>
              <h2
                class="pb-0 px-2 mt-4 text-truncate"
                style="font-size: 20px; font-weight: 700 !important"
              >
                <span v-if="lang() === 'ku'">{{ item.ku_name }}</span>
                <span v-if="lang() === 'ar'">{{ item.ar_name }}</span>
                <span v-if="lang() === 'en'">{{ item.en_name }}</span>
              </h2>
              <v-card-actions>
                <div class="mb-2"></div>
              </v-card-actions>
            </v-card>
          </router-link>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center mt-10">
        <v-btn
          to="/brands"
          color="#2c6c70"
          class="rounded-xl mx-auto mb-3 mt-2 text-capitalize"
          style="color: white"
          >{{ langkeyword("ALL_BRANDS") }}
          <v-icon class="mx-1">mdi mdi-chevron-right-circle</v-icon></v-btn
        >
      </v-row>

      </v-container>










<hr>










      <v-container class="mt-10 mb-10">
      <v-row class="d-flex justify-center">
        <h1 class="color_mode mb-2">{{ this.langkeyword("LATEST_PRODUCTCATEGORIES") }}</h1>
      </v-row>

      <v-row class="d-flex justify-center">
        <v-col
          v-for="(item, index) in productcategories"
          :key="index"
          cols="6"
          lg="2"
          sm="4"
          md="3"
        >
          <router-link
          :to="'/productcategories/' + item.id"
            style="text-decoration: none"
            class="color_mode"
          >
            <v-card
              style="border-radius: 15px !important"
              elevation="4"
              class="mx-auto bg_mode text-center"
              max-width="400"
            >
              <v-img
                class="white--text mx-auto bg_main"
                width="100%"
                height="200"
                style="background:#2e3651;"
                :src="uploadPath('/productcategories/' + item.avatar)"
              >
              </v-img>
              <h2
                class="pb-0 px-2 mt-4 text-truncate"
                style="font-size: 20px; font-weight: 700 !important"
              >
                <span v-if="lang() === 'ku'">{{ item.ku_name }}</span>
                <span v-if="lang() === 'ar'">{{ item.ar_name }}</span>
                <span v-if="lang() === 'en'">{{ item.en_name }}</span>
              </h2>
              <v-card-actions>
                <div class="mb-2"></div>
              </v-card-actions>
            </v-card>
          </router-link>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-center mt-10">
        <v-btn
          to="/productcategories"
          color="#2c6c70"
          class="rounded-xl mx-auto mb-3 mt-2 text-capitalize"
          style="color: white"
          >{{ langkeyword("ALL_PRODUCTCATEGORIES") }}
          <v-icon class="mx-1">mdi mdi-chevron-right-circle</v-icon></v-btn
        >
      </v-row>

      </v-container>




    <br />

    <div class="col-11 col-lg-12 col-md-12 mx-auto">
      <v-row class="mt-10 mb-10 bg_mode rounded-lg">
        <v-col cols="11" lg="12" md="12" class="text-center">
          <div class="d-flex flex-wrap justify-space-around align-center">
            <v-col class="text-center" cols="12" sm="6" lg="2" md="2">
              <v-btn
              x-large
                to="/products"
                color="#2c6c70"
                class="white--text rounded-xl pa-6"
                >{{ langkeyword("START_SHOPPING") }}</v-btn
              >
            </v-col>

            <v-col cols="12" sm="6" lg="3" md="3" class="text-center">
              <v-img class="mx-auto" src="../../assets/img/shop.svg"></v-img>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </div>



    <div class="col-11 col-lg-12 col-md-12 mx-auto">
      <v-row class="mt-10 mb-10 bg_mode rounded-lg">
        <v-col cols="11" lg="12" md="12" class="text-center">
          <div class="d-flex flex-wrap justify-space-around align-center">
            <v-col class="text-center" cols="12" sm="12" lg="4" md="6">
              <v-btn
               x-large
                to=""
                color="#2c6c70"
                class="white--text rounded-xl pa-6"
                >
                <span v-if="lang()==='ku'">{{ $store.state.setting.ku_name }}</span>
                <span v-if="lang()==='ar'">{{ $store.state.setting.ar_name }}</span>
                <span v-if="lang()==='en'">{{ $store.state.setting.en_name }}</span>
                </v-btn
              >
            </v-col>

            <v-col cols="12" sm="12" lg="8" md="6" class="text-center">
              <div class="mapouter">
<div class="gmap_canvas">
<iframe class="gmap_iframe rounded" width="100%" height="300px"
allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
frameborder="0" scrolling="no" 
marginheight="0" marginwidth="0" 
src="https://maps.google.com/maps?width=700&amp;
height=400&amp;
hl=en&amp;
q=Pasha Pharmacy:تەلاری پزیشکی پاشا&amp;
t=&amp;
z=15&amp;
ie=UTF8&amp;
iwloc=B&amp;
output=embed">
</iframe>
</div>
</div>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </div>





    <!-- end product -->

    <appFooter />
  </div>
</template>

<script>
// import navbar from "../../layouts/client/nav.vue";
import appFooter from "../../layouts/client/footer.vue";
import slider from "../../components/client/slider.vue";

// import { VueperSlides, VueperSlide } from 'vueperslides'
// import 'vueperslides/dist/vueperslides.css'

export default {
  components: { appFooter, slider },

  data() {
    return {
      resp: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  },
  computed: {
    products() {
      return this.$store.state.products.slice(0, 6).reverse();
    },
    brands() {
      return this.$store.state.brands.slice(0, 6).reverse();
    },
    productcategories() {
      return this.$store.state.productcategories.slice(0, 6).reverse();
    },
  },

  mounted() {
  },
};
</script>

<style  scoped>
.btn-play {
  transition: 0.2s;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

#hero {
  z-index: 0;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
</style>