<template>
  <div>
    <v-row class="d-flex justify-center mt-12 mb-4">
      <h2>{{ langkeyword("MYCART") }}</h2>
    </v-row>

    <v-row class="alert mt-4">
      <v-col
        class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
        v-if="error_msg != null"
      >
        <v-alert dense type="warning">{{ error_msg }}</v-alert>
      </v-col>
      <v-col
        class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
        v-if="success_msg != null"
      >
        <v-alert dense type="success">{{ success_msg }}</v-alert>
      </v-col>
    </v-row>

    <v-container class="mb-10">
      <v-row class="d-flex justify-center">
        <v-col cols="12" md="9" lg="9">
          <v-simple-table class="bg_mode rounded-lg">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="">{{ langkeyword("ITEM") }}</th>
                  <th class="">{{ langkeyword("PRICE") }}</th>
                  <th class="text-center">{{ langkeyword("QUANTITY") }}</th>
                  <th class="">{{ langkeyword("SUBTOTAL") }}</th>
                  <th class=""></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in cart" :key="item.id">
                  <td>
                    <v-list-item key="1">
                      <v-list-item-avatar>
                        <v-img
                          :src="uploadPath('/products/' + item.products_avatar)"
                        ></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>
                          <router-link
                            :to="'products/' + item.product_id"
                            style="
                               {
                                cursor: pointer;
                              }
                            "
                          >
                            <span link v-if="lang() === 'ku'">{{
                              item.products_ku_name
                            }}</span>
                            <span link v-if="lang() === 'ar'">{{
                              item.products_ar_name
                            }}</span>
                            <span link v-if="lang() === 'en'">{{
                              item.products_en_name
                            }}</span>
                          </router-link>
                        </v-list-item-title>
                        <!-- <v-list-item-subtitle>Lorem Ipsum</v-list-item-subtitle> -->
                      </v-list-item-content>
                    </v-list-item>
                  </td>
                  <td>${{ item.price }}</td>
                  <td>
                    <div class="d-flex justify-center align-center">
                      <v-btn
                        @click="plus(item.id)"
                        class="white--text"
                        x-small
                        icon
                        style="background: #00e676"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>

                      <v-btn text>{{ item.qty }}</v-btn>

                      <v-btn
                        @click="minus(item.id)"
                        class="white--text"
                        x-small
                        icon
                        style="background: #e63200"
                      >
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                    </div>
                  </td>
                  <td>${{ item.subtotal }}</td>
                  <td>
                    <v-btn
                      @click="remove(item.id)"
                      class="white--text"
                      x-small
                      icon
                      style="background: #e63200"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>

        <v-col
          class="bg_mode"
          cols="11"
          md="3"
          lg="3"
          style="border-radius: 10px"
        >
          <p class="mt-5 text-center">{{ langkeyword("TOTAL") }}</p>
          <hr />
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="text-center title" style="width: 50px">
                    <b>${{ total }}</b>
                  </td>
                </tr> 
              </tbody>
            </template>
          </v-simple-table>
          <div class="text-center">
            <v-dialog v-model="dialog" persistent max-width="290" v-if="total>0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="primary title p-4 w-100 white--text mt-5"
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  >{{ langkeyword("BUY") }}
                  <v-icon>mdi-cart-arrow-down</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  {{ langkeyword("ARE_YOU_SURE_YOU_WANT_TO_ORDER") }}
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="dialog = false">
                    {{ langkeyword("NO") }}
                  </v-btn>
                  <v-btn color="primary" text @click="order">
                    {{ langkeyword("YES") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <appFooter />
  </div>
</template>

<script>
import appFooter from "../../layouts/client/footer.vue";
import axios from "axios";
import NavRequests from "../../requests/client/Nav";
export default {
  components: { appFooter },

  data() {
    return {
      auth_id: this.$store.getters.getclientauth.id,
      cart: [],
      error_msg: null,
      success_msg: null,
      total: 0,
      dialog: false,
    };
  },

  computed: {},
  mounted() {
    this.authcheck();

    axios
      .post("/app/client/cart/getall", {
        getAllCart: true,
        account_id: this.auth_id,
      })
      .then((res) => {
        if (res.data.status === false) {
          this.success_msg = null;
          this.error_msg = this.langkeyword(res.data.msg);
        } else {
          this.error_msg = null;
          this.cart = res.data.data;
          this.total = res.data.total + 0;
        }
        this.cleanMessages();
      })
      .catch((err) => {
        console.log("response error " + err);
      });
  },
  methods: {
    authcheck() {
      NavRequests.checkAuth(
        {
          isAuthCheck: true,
          account_id: this.clientAuthId(),
          token: this.clientAuthToken(),
        },
        (res) => {
          if (res.data.status === false) {
            this.$router.push("/login");
          } else if (res.data.status === true) {
            this.$store.state.clientauth = res.data.data;
            this.username = this.$store.state.clientauth.name;
          }
        }
      );
    },

    cleanMessages() {
      setTimeout(() => {
        this.success_msg = null;
        this.error_msg = null;
      }, 3000);
    },
    plus(id) {
      axios
        .post("/app/client/cart/plus", {
          plusCart: true,
          cart_id: id,
          qty: 1,
        })
        .then((res) => {
          if (res.data.status === false) {
            this.success_msg = null;
            this.error_msg = this.langkeyword(res.data.msg);
          } else {
            this.error_msg = null;
            this.cart = res.data.data;
            this.total = res.data.total + 0;
          }
          this.cleanMessages();
        })
        .catch((err) => {
          console.log("response error " + err);
        });
    },
    minus(id) {
      axios
        .post("/app/client/cart/minus", {
          minusCart: true,
          cart_id: id,
          qty: 1,
        })
        .then((res) => {
          if (res.data.status === false) {
            this.success_msg = null;
            this.error_msg = this.langkeyword(res.data.msg);
          } else {
            this.error_msg = null;
            this.cart = res.data.data;
            this.total = res.data.total + 0;
          }
          this.cleanMessages();
        })
        .catch((err) => {
          console.log("response error " + err);
        });
    },
    remove(id) {
      axios
        .post("/app/client/cart/delete", {
          deleteCart: true,
          cart_id: id,
        })
        .then((res) => {
          if (res.data.status === false) {
            this.success_msg = null;
            this.error_msg = this.langkeyword(res.data.msg);
          } else {
            this.error_msg = null;
            this.cart = res.data.data;
            this.total = res.data.total + 0;
          }
          this.cleanMessages();
        })
        .catch((err) => {
          console.log("response error " + err);
        });
    },
    order() {
      axios
        .post("/app/client/cart/order", {
          orderCart: true,
          account_id: this.auth_id,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.status === false) {
            this.success_msg = null;
            this.error_msg = this.langkeyword(res.data.msg);
          } else {
            window.location.href = "";
          }
          this.cleanMessages();
        })
        .catch((err) => {
          console.log("response error " + err);
        });
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>