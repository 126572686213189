import Vue from 'vue'
import Vuex from 'vuex'
import AppRequests from '../requests/App'
Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    actlang: null,
    serverurl: null,
    dashboardauth: {},
    dashboardhome: {},
    clientauth: {},
    clienthome: {},
    setting: {},
    users: [],
    metas: [],
    contacts: [],
    socials: [],
    stores: [],
    productcategories: [],
    brands: [],
    sliders: [],
    accounts: [],
    products: [],
    productgalaries: [],

    orders: [],
    orderitems: [],

    notifications: [],


    direction: '',
  },
  getters: {
    getusers(state) {
      return state.users
    },
    getmetas(state) {
      return state.metas
    },
    gecontacts(state) {
      return state.contacts
    },
    getdashboardauth(state) {
      return state.dashboardauth
    },
    getdashboardhome(state) {
      return state.dashboardhome
    },
    getclientauth(state) {
      return state.clientauth
    },
    getclienthome(state) {
      return state.clienthome
    },
    getlang(state) {
      return state.lang
    },
    getdrirection(state) {
      return state.drirection
    },
    getsetting(state) {
      return state.setting
    },
    getsocials(state) {
      return state.socials
    },

    getstores(state) {
      return state.stores
    },

    getbrands(state) {
      return state.brands
    },

    getsliders(state) {
      return state.sliders
    },

    getaccounts(state) {
      return state.accounts
    },

    getproductcategories(state) {
      return state.productcategories
    },

    getproducts(state) {
      return state.products
    },
    getproductgalaries(state) {
      return state.productgalaries
    },
    getorders(state) {
      return state.orders
    },
    getorderitems(state) {
      return state.orderitems
    },

    getnotifications(state) {
      return state.notifications
    },

  },
  mutations: {
    fetchData(state) {
      AppRequests.getAll({ isLoadApp: true }, (res) => {
        state.setting = res.data.fetchsetting
        state.socials = res.data.getsocials
        state.metas = res.data.getmetas
        state.contacts = res.data.getcontacts
        state.users = res.data.getusers
        state.stores = res.data.getstores
        state.productcategories = res.data.getproductcategories
        state.brands = res.data.getbrands
        state.sliders = res.data.getsliders
        state.accounts = res.data.getaccounts
        state.products = res.data.getproducts
        state.productgalaries = res.data.getproductgalaries
        state.orders = res.data.getorders
        state.orderitems = res.data.getorderitems
        state.notifications = res.data.getnotifications
      })
      AppRequests.countAll({ isLoadApp: true }, (res) => {
        state.dashboardhome = res.data
        state.clienthome = res.data
      })
    }
  },
  actions: {

  },
  modules: {
  }
})
