import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "@/plugins/vuetify"; // path to vuetify expor
import axios from "axios";
import VueMeta from "vue-meta";
import VueAgile from "vue-agile";

//const serverUrl = 'http://localhost/work/app-kalian'
const serverUrl = "https://kalyan-app.com";
store.state.serverurl = serverUrl;
Vue.config.productionTip = false;
axios.defaults.baseURL = serverUrl + "/api";

Vue.use(VueAgile);
Vue.use(VueMeta);
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
