<template>
    <div>
      <v-row class="d-flex justify-center mt-12 mb-4">
        <h2>{{ langkeyword("ORDER") }} - #{{ order.ordercode }}</h2>
      </v-row>
  
      <v-row class="alert mt-4">
        <v-col
          class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
          v-if="error_msg != null"
        >
          <v-alert dense type="warning">{{ error_msg }}</v-alert>
        </v-col>
        <v-col
          class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
          v-if="success_msg != null"
        >
          <v-alert dense type="success">{{ success_msg }}</v-alert>
        </v-col>
      </v-row>
  
      <v-container class="mb-10">
        <v-row class="d-flex justify-center">
          <v-col cols="12" md="9" lg="9">
            <v-simple-table class="bg_mode rounded-lg">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">{{ langkeyword("ITEM") }}</th>
                    <th class="text-center">{{ langkeyword("PRICE") }}</th>
                    <th class="text-center">{{ langkeyword("QUANTITY") }}</th>
                    <th class="text-center">{{ langkeyword("SUBTOTAL") }}</th>
                    <th class="text-center"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in orderitems" :key="item.id">
                    <td class="text-center">
                      <v-list-item key="1">
                        <v-list-item-avatar>
                          <v-img
                            :src="uploadPath('/products/' + item.products_avatar)"
                          ></v-img>
                        </v-list-item-avatar>
  
                        <v-list-item-content>
                          <v-list-item-title>
                            <router-link
                              :to="'/products/' + item.product_id"
                              style="
                                 {
                                  cursor: pointer;
                                }
                              "
                            >
                              <span link v-if="lang() === 'ku'">{{
                                item.products_ku_name
                              }}</span>
                              <span link v-if="lang() === 'ar'">{{
                                item.products_ar_name
                              }}</span>
                              <span link v-if="lang() === 'en'">{{
                                item.products_en_name
                              }}</span>
                            </router-link>
                          </v-list-item-title>
                          <!-- <v-list-item-subtitle>Lorem Ipsum</v-list-item-subtitle> -->
                        </v-list-item-content>
                      </v-list-item>
                    </td>
                    <td class="text-center">${{ item.price }}</td>
                    <td class="text-center">{{ item.qty }}</td>
                    <td class="text-center">${{ item.subtotal }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
  
          <v-col
            class="bg_mode"
            cols="11"
            md="3"
            lg="3"
            style="border-radius: 10px"
          >
            <p class="mt-5 text-center">{{ langkeyword("TOTAL") }}</p>
            <hr />
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td class="text-center title" style="width: 50px">
                      <b>${{ total }}</b>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="text-center">


                 <v-btn class="purple white--text mt-5  rounded px-2 m-auto text-center" outline 
                 v-if="total!=0 && order.status==='ordered'">{{ langkeyword('ORDER_WATING') }}
                <v-icon>mdi-order-arrow-down</v-icon>
                </v-btn>
                  <v-btn class="red white--text mt-5  rounded px-2 m-auto text-center" outline 
                  v-if="total!=0 && order.status==='checking'">{{ langkeyword('ORDER_CHECKING') }}
                <v-icon>mdi-order-arrow-down</v-icon>
                </v-btn>
                  <v-btn class="warning white--text mt-5  rounded px-2 m-auto text-center" outline 
                  v-if="total!=0 && order.status==='delivering'">{{ langkeyword('ORDER_DELIVERING') }}
                <v-icon>mdi-order-arrow-down</v-icon>
                </v-btn>
                  <v-btn class="success white--text mt-5  rounded px-2 m-auto text-center" outline 
                  v-if="total!=0 && order.status==='delivered'">{{ langkeyword('ORDER_DELIVERED') }}
                <v-icon>mdi-order-arrow-down</v-icon>
                </v-btn>  



                

            </div>
          </v-col>
        </v-row>
      </v-container>
  
      <appFooter />
    </div>
  </template>
  
  <script>
  import appFooter from "../../layouts/client/footer.vue";
  import axios from "axios";
  import NavRequests from '../../requests/client/Nav'
  export default {
    components: { appFooter },
  
    data() {
      return {
        auth_id: this.$store.getters.getclientauth.id,
        orderitems:[],
        order:{},
        error_msg: null,
        success_msg: null,
        total: 0,
      };
    },
  
    computed: {},
    mounted() {

      this.authcheck()
  
    


      axios
        .post("/app/client/orders/orderitems", {
            getAllOrderItems: true,
            order_id: this.$route.params.id,
        })
        .then((res) => {
          if (res.data.status === false) {
            this.success_msg = null;
            this.error_msg = this.langkeyword(res.data.msg);
          } else {
            this.error_msg = null;
            this.orderitems = res.data.data;
            this.order = res.data.order;
            this.total = res.data.total + 0;
          }
          this.cleanMessages();
        })
        .catch((err) => {
          console.log("response error " + err);
        });
    },
    methods: {


      authcheck(){
    NavRequests.checkAuth({
    isAuthCheck:true,
    account_id:this.clientAuthId(),
    token:this.clientAuthToken(),
  },(res)=>{
    if (res.data.status===false){
this.$router.push('/login')
    }
    else if(res.data.status===true) {
     this.$store.state.clientauth = res.data.data
     this.username = this.$store.state.clientauth.name
    }
  })
      },




      cleanMessages() {
        setTimeout(() => {
          this.success_msg = null;
          this.error_msg = null;
        }, 3000);
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  </style>