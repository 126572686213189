import axios from 'axios'

 
export default {

    async initApp(params,callback){
        return await axios.post("/app/init",params)
        .then(res=>{
            callback(res)
        })
        .catch(err=>{
            console.clear()
            console.log(' APP RUNNING IS NOT SUCCESSFULL ')
            console.log('response error '+err)
            console.clear()
        })
    },



    async getAll(params,callback){
        return await axios.post("/app/init",params)
        .then(res=>{
            callback(res)
        })
    },

    async countAll(params,callback){
        return await axios.post("/app/init",params)
        .then(res=>{
            callback(res)
        })
    },

    




}
 
