<template>
  <div>
    <v-footer class="bg_mode mt-15 client-footer">
      <v-container>
        <div class="d-flex justify-space-between flex-wrap mt-10">
          <v-col cols="12" md="4" lg="4" xl="4" class="text-center mx-auto">
            <v-img
              max-width="340"
              :src="uploadPath('/setting/' + $store.state.setting.logo)"
              width="150px"
              class="mx-auto"
            ></v-img>
          </v-col>

          <v-col cols="12" sm="10" md="4" lg="4" xl="4" class="text-center mx-auto">
            <h3 class="indesktop-center mb-4">{{ langkeyword("ABOUT_US") }}</h3>
            <hr>
            <p class="text-opacity mt-5 indesktop-center">
              <span v-if="lang() === 'ku'">{{
                this.$store.state.setting.ku_about.slice(0,300)
              }}</span>
              <span v-if="lang() === 'ar'">{{
                this.$store.state.setting.ar_about.slice(0,300)
              }}</span>
              <span v-if="lang() === 'en'">{{
                this.$store.state.setting.en_about.slice(0,300)
              }}</span>
            </p>
          </v-col>

          <v-col cols="12" sm="10" md="4" lg="4" xl="4" class="text-center mx-auto">
            <h3 class="indesktop-center mb-4">
              {{ langkeyword("CONTACT_US") }}
            </h3>
            <hr>
            <div class="text-center">
              
              <p class="mt-4 mx-2">
                <v-icon>mdi-phone</v-icon>  {{ this.$store.state.setting.phone }} 
              </p>
            </div>
            <div class="text-center">
              
              <p class="mt-4 mx-2">
                <v-icon>mdi-email</v-icon>  {{ this.$store.state.setting.email }} 
              </p>
            </div>
            <div class="text-center">
              
              <p class="mt-4 mx-2"><v-icon>mdi-map-marker</v-icon> sulaymaniyah iraq </p>
            </div>

            <div class="text-center mt-4 text-center mx-auto">
              <a
                v-for="(item, index) in socials"
                :key="index"
                :href="item.link"
                target="_blank"
                style="text-decoration: none"
              >
                <v-icon class="mx-1">{{ item.icon }}</v-icon></a
              >
            </div>
          </v-col>
        </div>
      </v-container>

      <v-col class="text-center mb-15" cols="12">
<h4>
  <a class="text-decoration-none color-main " href="https://www.facebook.com/peshawaGroup" target="_blank">
  <v-btn color="primary" class="white--text">
    {{ new Date().getFullYear() }} —
          <strong>{{ langkeyword("APP_DEVELOPER") }}</strong>
  </v-btn>        
  </a>
</h4>
        
      </v-col>
    </v-footer>
  </div>
</template>

<style scoped>
</style>

<script>
export default {
  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
  }),
  computed: {
    socials() {
      return this.$store.state.socials;
    },
  },
};
</script>
