<template>
    <div>
      <!-- <navbar/> -->
  
      <v-container class="show_in_desktop">
        <v-row
          class="d-flex justify-center mt-16 bg_mode pa-3"
          style="border-left: 2px solid #2c6c70; border-right: 2px solid #2c6c70"
        >
          <h2 class="color-main"> 
            <span v-if="lang()==='ku'">{{ info.ku_name }}</span>
            <span v-if="lang()==='ar'">{{ info.ar_name }}</span>
            <span v-if="lang()==='en'">{{ info.en_name }}</span>
          </h2>

        </v-row>
      </v-container>
  
      <v-container class="show_in_mobile">
        <v-row class="d-flex justify-center mt-16 pa-3">
          <h2 class="color-main"> 
            <span v-if="lang()==='ku'">{{ info.ku_name }}</span>
            <span v-if="lang()==='ar'">{{ info.ar_name }}</span>
            <span v-if="lang()==='en'">{{ info.en_name }}</span>
          </h2>
        </v-row>
      </v-container>
  
      <!-- items -->
      <div class="mt-6 mb-10">
        <v-row>
          <v-col cols="12" lg="6" xl="6" sm="8" class="pa-5 mx-auto text-center">
          <v-text-field
          clearable
            class="text-center bg_mode rounded-pill px-3"
            :label="langkeyword('SEARCH')"
            v-model="search"
          ></v-text-field>
        </v-col>

        </v-row>
  
        <v-row class="pa-lg-6 d-flex flex-wrap">
          <v-col cols="12" md="12" lg="12">
            <v-row
              align="center"
              class="d-flex px-8 justify-space-between"
              style="align-items: center !important"
            >
              <div>
                <p style="font-size: 20px !important">
                  {{ langkeyword("PRODUCTS") }}
                </p>
              </div>
  
              <div class="d-flex justify-space-around">
                <v-select
                  :items="[10, 15, 20, 50, 100]"
                  v-model="itemnumber"
                  id="showProduct"
                  dense
                  outlined
                  hide-details
                  
                  style="width: 80px"
                ></v-select>
              </div>
            </v-row>
  
            <!-- card items   -->
            <v-row class="d-flex justify-center flex-wrap pa-2">
              <v-row class="d-flex justify-center" v-if="products.length == 0">
                <h3>{{ langkeyword("NOTFOUND") }}</h3>
              </v-row>
  
              <v-col
                v-for="(item, index) in products"
                :key="index"
                cols="6"
                sm="4"
                lg="2"
                xl="2"
                md="3"
              >
                <v-card
                outlined
                shaped
                elevation="5"
                  class="mx-auto bg_mode text-center"
                  max-width="400"
                >
                  <v-img
                    class="white--text mx-auto"
                    width="100%"
                    height="200"
                    style="object-fit:contain;"
                    :src="uploadPath('/products/' + item.avatar)"
                  >
                  </v-img>
                  <h2
                    class="pb-0 px-2 mt-4 text-truncate"
                    style="font-size: 20px; font-weight: 700 !important"
                  >
                    <span v-if="lang() === 'ku'">{{ item.ku_name }}</span>
                    <span v-if="lang() === 'ar'">{{ item.ar_name }}</span>
                    <span v-if="lang() === 'en'">{{ item.en_name }}</span>
                  </h2>
                  <v-card-subtitle class="pb-0" style="font-size: 16px">
                    <span v-if="lang() === 'ku'">{{
                      item.productcategories_ku_name
                    }}</span>
                    <span v-if="lang() === 'ar'">{{
                      item.productcategories_ar_name
                    }}</span>
                    <span v-if="lang() === 'en'">{{
                      item.productcategories_en_name
                    }}</span>
                  </v-card-subtitle>
  
                  <v-card-actions>
                   
                    <v-btn
                      :to="'/products/' + item.id"
                      text
                      class="mx-auto mb-3 mt-2 text-capitalize"
                      small
                      >{{ langkeyword("READ_MORE") }}
                      <v-icon class="mx-1" color="#2c6c70"
                        >mdi mdi-chevron-right-circle</v-icon
                      ></v-btn
                    >
  
                    <div class="mb-2"></div>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <!-- end card items -->
  
            <v-row class="mb-16"> </v-row>
          </v-col>
        </v-row>
      </div>
  
      <appFooter />
    </div>
  </template>
  
  <script>
  // import navbar from "../../../layouts/client/nav.vue";
  import axios from "axios";
  import appFooter from "../../layouts/client/footer.vue";
  export default {
    components: { appFooter },
    data() {
      return {
        info:{},
        dialog: false,
        itemnumber: 10,
        productcategories_filter: [],
        brands_filter: [],
        search: "",
      };
    },
    computed: {
      products() {
        if (
          this.productcategories_filter.length === 0 &&
          this.brands_filter.length === 0
        ) {
          if (this.search != "") {
            return this.$store.state.products
              .filter((entry) => {
                return (
                  this.search.toLowerCase().includes(entry.en_name) ||
                  this.search
                    .toLowerCase()
                    .includes(entry.ar_name.toLowerCase()) ||
                  this.search.toLowerCase().includes(entry.ku_name)
                );
              })
              .slice(0, this.itemnumber);
          } else {
            return this.$store.state.products.slice(0, this.itemnumber);
          }
        }
  
        if (
          this.productcategories_filter.length != 0 &&
          this.brands_filter.length === 0
        ) {
          if (this.search != "") {
            return this.$store.state.products
              .filter((entry) => {
                return (
                  this.productcategories_filter.includes(
                    entry.productcategory_id
                  ) &&
                  (this.search.toLowerCase().includes(entry.en_name) ||
                    this.search
                      .toLowerCase()
                      .includes(entry.ar_name.toLowerCase()) ||
                    this.search.toLowerCase().includes(entry.ku_name))
                );
              })
              .slice(0, this.itemnumber);
          } else {
            return this.$store.state.products
              .filter((entry) => {
                return this.productcategories_filter.includes(
                  entry.productcategory_id
                );
              })
              .slice(0, this.itemnumber);
          }
        }
  
        if (
          this.productcategories_filter.length === 0 &&
          this.brands_filter.length != 0
        ) {
          if (this.search != "") {
            return this.$store.state.products
              .filter((entry) => {
                return (
                  this.brands_filter.includes(entry.brand_id) &&
                  (this.search.toLowerCase().includes(entry.en_name) ||
                    this.search
                      .toLowerCase()
                      .includes(entry.ar_name.toLowerCase()) ||
                    this.search.toLowerCase().includes(entry.ku_name))
                );
              })
              .slice(0, this.itemnumber);
          } else {
            return this.$store.state.products
              .filter((entry) => {
                return this.brands_filter.includes(entry.brand_id);
              })
              .slice(0, this.itemnumber);
          }
        } else {
          return this.$store.state.products.slice(0, this.itemnumber);
        }
      },
    },
    mounted() {
        axios
      .post("/app/client/brands/getone", {
        getOneBrands: true,
        brand_id: this.$route.params.id,
      })
      .then((res) => {
        if (res.data.status == true) {
            this.info = res.data.data;
            this.brands_filter.push(parseInt(this.$route.params.id))
        } else {
          console.log(res.data);
          this.$router.push("/404");
        }
      })
      .catch((err) => {
        console.log("response error " + err);
      });


    },
    methods: {
    },
  };
  </script>
  
  <style scoped>
  .scroll {
    margin: 4px, 4px;
    padding: 4px;
    height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify;
  }
  </style>