<template>
    <div>
        <v-container>
            


<v-row class="d-flex justify-center mt-10">
    

        <v-col
          cols="12"
          lg="9"
          md="9"
        >

       
            <div class="d-flex flex-wrap justify-space-between align-center bg_mode pa-13 rounded-lg" >

                <v-col cols="11" lg="3" md="3" class="text-center">

                    <center><span class="teal--text rabar-font pb-2 mb-2 mt-2" v-if="token!=''">{{checkemail}}</span></center>
                    <center><span class="orange--text rabar-font">{{error_auth}}</span></center>
                <v-img  min-width="300" class="mx-auto" src="../../../assets/img/new_pass.svg"></v-img>
       
            </v-col>

            
                <v-col cols="12" lg="5" md="6">
                    
                    <v-row align="center" ali class="d-flex justify-center mt-10">


                        <!-- alert  -->

                        <!-- <Center>
                            <v-alert
                                dense
                                text
                                type="success"
                                >
                                I'm a dense alert with the <strong>text</strong> prop and a <strong>type</strong> of success
                                </v-alert>
                            <v-alert
                                dense
                                text
                                type="error"
                                >
                                I'm a dense alert with the <strong>text</strong> prop and a <strong>type</strong> of error
                                </v-alert>
                        </Center> -->

                        <v-text-field filled
                                rounded type="number" append-icon="mdi-numeric" :label="langkeyword('CODE')" 
                                    required   class="code  rabar-font  " v-model="code"
                                    :disabled="disabled" :error-messages="error" ref="code">
                                </v-text-field>


                    </v-row>
                    <v-row align="center" ali class="d-flex justify-center mt-4">

                        <v-text-field   filled
                                rounded class=" rabar-font" v-model="newpass"
                                    :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'" :label="langkeyword('NEWPASSWORD')"
                                    name="password" :type="show ? 'input' : 'password'" hide-details="auto"
                                    @click:append="show = !show" ref="pass" />
                    </v-row>


                    <v-row align="center" ali class="d-flex justify-center mt-10">

                        <v-btn @click="reset" color="primary">{{langkeyword('UPDATE')}}</v-btn>
                        
                    </v-row>

                    
                   <v-row align="center" ali class="d-flex justify-center mt-6">
                    <v-btn class="text-forget rabar-font letter-spacing-0" 
                                    color="orange" text @click="$router.push('/forget')">
                                    {{langkeyword('IS_CODE_NOT_SENT')}} 
                                </v-btn>
                   </v-row>

                         
                </v-col>
  
    
            </div>
      
        </v-col>
      </v-row>

        </v-container>
    </div>
</template>

<script>
import resetRequests from '../../../requests/client/auth/Reset'
    export default {
        data() {
            return {
                show: false,
                error: null,
                passErr: null,
                disabled: false,
                code: '', 
                token: this.$route.query.token,
                newpass: '',
                error_auth: null,
                checkemail:this.langkeyword('CHECKEMAIL')
            }
        },
        methods: {
    ifAuthorized(){
    resetRequests.ifAuthorized({
    isAuthCheck:true,
    user_id:this.clientAuthId(),
    token:this.clientAuthToken(),
  },(res)=>{
    if (res.data.status===true){
this.$router.push('/MyProfile')
    }
  })
      },
    reset() {
resetRequests.authReset({
    isReset:true,
    code:this.code,
    newpass:this.newpass,
    token:this.token,
  },(res)=>{
    if (res.data.status===false){
this.error_auth = this.langkeyword(res.data.msg)
    }
    else if (res.data.status===true) {
    this.$router.push('/login')
    }
  })

            },
        },
           mounted(){
               this.ifAuthorized()
           }
    }
</script>

<style lang="scss" scoped>

</style>