<template>
  <div>
    <!--  -->
    <v-navigation-drawer
      v-if="lang() == 'en'"
      v-model="mobileDrawer"
      temporary
      app
      left
      style="z-index: 1000000 !important"
    >
      <v-list>
        <v-list-item link to="/" @click="mobileDrawer = false">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ langkeyword("HOME") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/products" @click="mobileDrawer = false">
          <v-list-item-icon>
            <v-icon>mdi-elevator-passenger</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ langkeyword("PRODUCTS") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/productcategories" @click="mobileDrawer = false">
          <v-list-item-icon>
            <v-icon>mdi-toolbox-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              langkeyword("PRODUCTCATEGORIES")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/brands" @click="mobileDrawer = false">
          <v-list-item-icon>
            <v-icon>mdi-briefcase</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ langkeyword("BRANDS") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/about" @click="mobileDrawer = false">
          <v-list-item-icon>
            <v-icon>mdi-information</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ langkeyword("ABOUT") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/contact" @click="mobileDrawer = false">
          <v-list-item-icon>
            <v-icon>mdi-phone-message</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ langkeyword("CONTACT") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/setting" @click="mobileDrawer = false">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ langkeyword("SETTING") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/cart" @click="mobileDrawer = false">
          <v-list-item-icon>
            <v-icon>mdi-cart</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ langkeyword("MYCART") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- for kurdish and arabic  -->
    <v-navigation-drawer
      v-if="lang() != 'en'"
      v-model="mobileDrawer"
      temporary
      app
      right
      style="z-index: 1000000 !important"
    >
      <v-list>
        <v-list-item link to="/" @click="mobileDrawer = false">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              this.langkeyword("HOME")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/products" @click="mobileDrawer = false">
          <v-list-item-icon>
            <v-icon>mdi-elevator-passenger</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              this.langkeyword("PRODUCTS")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/productcategories" @click="mobileDrawer = false">
          <v-list-item-icon>
            <v-icon>mdi-toolbox-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              this.langkeyword("PRODUCTCATEGORIES")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/brands" @click="mobileDrawer = false">
          <v-list-item-icon>
            <v-icon>mdi-projector</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              this.langkeyword("BRANDS")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="username != null"
          link
          to="/orders"
          @click="mobileDrawer = false"
        >
          <v-list-item-icon>
            <v-icon>mdi-cart-arrow-down</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              this.langkeyword("MYORDERS")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="username != null"
          link
          to="/cart"
          @click="mobileDrawer = false"
        >
          <v-list-item-icon>
            <v-icon>mdi-cart</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              this.langkeyword("MYCART")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="username != null"
          link
          to="/myprofile"
          @click="mobileDrawer = false"
        >
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              this.langkeyword("MYPROFILE")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/about" @click="mobileDrawer = false">
          <v-list-item-icon>
            <v-icon>mdi-information</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              this.langkeyword("ABOUT_US")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/contact" @click="mobileDrawer = false">
          <v-list-item-icon>
            <v-icon>mdi-phone-message</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              this.langkeyword("CONTACT")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link to="/setting" @click="mobileDrawer = false">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              this.langkeyword("SETTING")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="username == null || username == ''"
          link
          to="/login"
          @click="mobileDrawer = false"
        >
          <v-list-item-icon>
            <v-icon>mdi-login</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              this.langkeyword("LOGIN")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="username != null" @click="logoutFunc()">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              this.langkeyword("LOGOUT")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar class="navbarColor" v-if="isXs" style="z-index: 1000 !important">
      <v-row>
        <a href="">
          <v-img
            @click="changeLang('en')"
            max-height="50"
            max-width="50"
            class="mx-3"
            src="../../assets/img/english.png"
          ></v-img>
        </a>

        <a href="">
          <v-img
            @click="changeLang('ku')"
            max-height="50"
            max-width="50"
            class="mx-3"
            src="../../assets/img/kurd.png"
          ></v-img>
        </a>

        <a href="">
          <v-img
            @click="changeLang('ar')"
            max-height="50"
            max-width="50"
            class="mx-3"
            src="../../assets/img/arabic.png"
          ></v-img>
        </a>
      </v-row>
    </v-app-bar>

    <v-app-bar class="navbarColor" v-if="isXs" style="z-index: 1000 !important">
      <!-- <v-app-bar-title>
        <router-link to="/">
        <v-img src="../../assets/img/medicine.png" max-width="220px" />
        <h5 class="white--text">Kalian</h5>
      </router-link>
      </v-app-bar-title> -->

      <router-link to="/">
        <v-card
          height="63"
          class="px-3 bg_mode"
          :class="lang() == 'en' ? 'border_radius_en' : 'border_radius_ku'"
          :style="
            lang() == 'en'
              ? 'transform:translate(-20px,0px)'
              : 'transform:translate(20px,0px)'
          "
        >
          <img 
            :src="uploadPath('/setting/' + $store.state.setting.logo)"
            width="130px"
          />
        </v-card>
        <!-- <h3 class="">Kalian</h3> -->
      </router-link>

      <v-spacer></v-spacer>

      <navItem
        to="/"
        :text="this.langkeyword('HOME')"
        color="red"
        icon="mdi-home-variant"
        TooltipText="Home"
      />

      <navItem
        to="/products"
        :text="this.langkeyword('PRODUCTS')"
        icon="mdi-package"
        TooltipText="Products"
      />

      <navItem
        to="/productcategories"
        :text="this.langkeyword('PRODUCTCATEGORIES')"
        icon="mdi-shape"
        TooltipText="Services"
      />

      <navItem
        to="/brands"
        :text="this.langkeyword('BRANDS')"
        icon="mdi-cart"
        TooltipText="Projects"
      />

      <navItem
        to="/about"
        :text="this.langkeyword('ABOUT_US')"
        icon="mdi-information"
      />

      <navItem
        to="/contact"
        :text="this.langkeyword('CONTACT')"
        icon="mdi-information"
      />

      <v-spacer></v-spacer>


      <v-btn v-if="username != null" to="/cart" fab small class="mx-1 white--text" color="primary"
        ><v-icon>mdi-cart</v-icon></v-btn
      >
      <v-btn v-if="username != null" to="/orders" fab small class="mx-1 white--text" color="primary"
        ><v-icon>mdi-cart-arrow-down</v-icon></v-btn
      >

      <v-menu
        class="bg_mode"
        v-model="menu"
        :nudge-width="200"
        offset-x
        :close-on-content-click="false"
      >
        <template #activator="menu">
          <v-tooltip bottom>
            <template #activator="tooltip">
              <v-btn
                fab
                small
                class="mx-1 white--text" color="primary"
                v-on="{ ...menu.on, ...tooltip.on }"
              >
                <v-icon>mdi-account</v-icon>
              </v-btn>
            </template>
            <span>{{ langkeyword("PROFILE") }}</span>
          </v-tooltip>
        </template>

        <v-card>
          <v-list v-if="username != null">
            <v-list-item to="/myprofile">
              <v-list-item-avatar>
                <img style="object-fit:contain;" 
                v-if="this.$store.state.clientauth.avatar != null"
                :src="uploadPath('/accounts/'+this.$store.state.clientauth.avatar)"
                />
                <img style="object-fit:contain;" 
                v-if="this.$store.state.clientauth.avatar === null"
                src="../../assets/img/footer-logo.png"
                />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ username }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider class="color_mode"></v-divider>

          <v-list>
            <v-list-item>
              <v-list-item-title v-if="$vuetify.theme.dark">{{
                this.langkeyword("LIGHTMODE")
              }}</v-list-item-title>
              <v-list-item-title v-else>{{
                this.langkeyword("DARKMODE")
              }}</v-list-item-title>
              <v-list-item-action>
                <v-switch
                  hide-details
                  @click="mode()"
                  v-model="darktheme"
                ></v-switch>
              </v-list-item-action>
            </v-list-item>

            <!-- 
                <v-menu  v-model="lang_menu" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="text-capitalize rabar_font" v-bind="attrs" v-on="on" text>
                  <v-icon>mdi-translate</v-icon>
                  <span class="rabar_font">{{ activeLang }}</span>
                  <v-icon >mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item link v-for="(lang, index) in langs" :key="index">
                  <v-list-item-title class="rabar_font" v-on:click="changeLang(lang.value)">{{ lang.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> -->

            <v-list-item
              v-if="username == null || username == ''"
              link
              to="/login"
              class="rounded-lg"
            >
              <v-icon>mdi-login</v-icon> &nbsp; {{ this.langkeyword("LOGIN") }}
            </v-list-item>

            <v-list-item
              @click="logout()"
              v-if="username != null"
              class="rounded-lg"
            >
              <v-icon>mdi-logout</v-icon> &nbsp;
              {{ this.langkeyword("LOGOUT") }}
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <!-- for mobile -->

    <v-app-bar app class="px-2 bg_mode" v-else style="z-index: 1000 !important">
      <v-app-bar-title>
        <router-link to="/">
          <v-card height="55">
            <img
              :src="uploadPath('/setting/' + $store.state.setting.logo)"
              style="object-fit: cover"
              width="130px"
            />
          </v-card>
          <!-- <h3 class="">Kalian</h3> -->
        </router-link>
      </v-app-bar-title>

      <v-spacer></v-spacer>

      <v-btn
        text
        fab
        small
        elevation="2"
        @click.stop="mobileDrawer = !mobileDrawer"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>


<div class="bottom-nav">
  <v-bottom-navigation
      :value="value"
      color="primary"
      horizontal
      fixed
      grow
      shift
      class="text-center"
    >
      <v-btn class="text-center bg_mode bottom-nav-item" color="color_mode" to="/">
        <v-icon>mdi mdi-home-variant</v-icon>
        <span>{{ langkeyword("HOME") }}</span>
      </v-btn>


      <v-btn
        class="text-center bg_mode bottom-nav-item"
        color="color_mode"
        v-if="username == null"
        to="/login"
      >
        <v-icon>mdi mdi-login</v-icon>
        <span>{{ langkeyword("LOGIN") }}</span>
      </v-btn>


      <v-btn
        class="text-center bg_mode bottom-nav-item"
        color="color_mode"
        v-if="username == null"
        to="/register"
      >
        <v-icon>mdi mdi-account-plus</v-icon>
        <span>{{ langkeyword("DONTHAVE_AN_ACCOUNT") }}</span>
      </v-btn>




      <v-btn
        class="text-center bg_mode bottom-nav-item"
        color="color_mode"
        v-if="username != null"
        to="/myprofile"
      >
        <v-icon>mdi mdi-account</v-icon>
        <span>{{ langkeyword("MYPROFILE") }}</span>
      </v-btn>
      <v-btn
        class="text-center bg_mode bottom-nav-item"
        color="color_mode"
        v-if="username != null"
        to="/cart"
      >
        <v-icon>mdi mdi-cart</v-icon>
        <span>{{ langkeyword("MYCART") }}</span>
      </v-btn>
      <v-btn
        class="text-center bg_mode bottom-nav-item"
        color="color_mode"
        v-if="username != null"
        to="/orders"
      >
        <v-icon>mdi mdi-cart-arrow-down</v-icon>
        <span>{{ langkeyword("MYORDERS") }}</span>
      </v-btn>
    </v-bottom-navigation>
</div>

  </div>
</template>





<script>
import Vue from "vue";
// import notification from "../../components/client/navbarNotification.vue"
import navItem from "../../components/client/navItem.vue";
// import i18n from '../../i18n'
import NavRequests from "../../requests/client/Nav";
export default {
  data: () => ({
    username: Vue.prototype.getters().getclientauth.name,
    mobileDrawer: false,
    menu: false,
    drawer: null,
    isXs: false,
    isXs2: false,
    goDark: false,
    darktheme: false,
    lang_menu: false,
    lang_menu_mobile: false,
    langs: [],
    activeLang: "",
    radioGroup: "1",
    // testt:i18n.t('ENGLISH')
    value: 0,
  }),
  components: { navItem },
  props: {
    color: String,
    flat: Boolean,
  },
  methods: {
    logout() {
      NavRequests.logOut(
        {
          isLogout: true,
          account_id: this.clientAuthId(),
        },
        (res) => {
          if (res.data.status === true) {
            localStorage.removeItem("clientauthId");
            localStorage.removeItem("clientauthToken");
            this.$store.state.clientauth = {};
            window.location.href = "/login";
          }
        }
      );
    },

    changeLang(lang) {
      localStorage.setItem("lang", lang);
      //  let activeLang=localStorage.getItem('lang')
      window.location.href = "";
    },

    onResize() {
      this.isXs = window.innerWidth > 850;
      this.isXs2 = window.innerWidth > 850;
    },
    mode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("clientTheme", this.$vuetify.theme.dark.toString());
    },

    authcheck() {
      NavRequests.checkAuth(
        {
          isAuthCheck: true,
          account_id: this.clientAuthId(),
          token: this.clientAuthToken(),
        },
        (res) => {
          if (res.data.status === false) {
            //this.$router.push('/login')
          } else if (res.data.status === true) {
            this.$store.state.clientauth = res.data.data;
            this.username = this.$store.state.clientauth.name;
          }
        }
      );
    },

    logoutFunc() {
      this.mobileDrawer = false;
      this.logout();
    },
  },

  mounted() {
    this.authcheck();
    let updatelangs = [
      { title: this.langkeyword("ENGLISH"), value: "en", image: "english.png" },
      { title: this.langkeyword("KURDISH"), value: "ku", image: "kurd.png" },
      { title: this.langkeyword("ARABIC"), value: "ar", image: "arabic.png" },
    ];
    this.langs = updatelangs;

    if (this.lang() === "en") {
      this.activeLang = this.langkeyword("ENGLISH");
      this.arrowIcon = "mdi-arrow-left";
    }
    if (this.lang() === "ar") {
      this.activeLang = this.langkeyword("ARABIC");
      this.arrowIcon = "mdi-arrow-right";
    }
    if (this.lang() === "ku") {
      this.activeLang = this.langkeyword("KURDISH");
      this.arrowIcon = "mdi-arrow-right";
    }

    const AppLanguage = this.$store.state.actlang;
    if (AppLanguage == "ku" || AppLanguage == "ar") {
      this.$vuetify.rtl = true;
    }
    if (AppLanguage == "en") {
      this.$vuetify.rtl = false;
    }

    // if (AppLanguage=='kurdish' || AppLanguage=='arabic') {
    //    this.$vuetify.rtl = true
    // }
    // if (AppLanguage=='english') {
    //    this.$vuetify.rtl = false
    // }

    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });

    const theme = localStorage.getItem("clientTheme");
    if (theme) {
      if (theme == "true") {
        this.$vuetify.theme.dark = true;
        this.darktheme = true;
        this.$store.commit("set_theme", true);
      } else {
        this.$vuetify.theme.dark = false;
        this.darktheme = false;
        this.$store.commit("set_theme", false);
      }
    }
  },
};
</script>




<style scoped>
.v-toolbar {
  transition: 0.6s;
}

.expand {
  height: 80px !important;
  padding-top: 10px;
}

/* mobile sidebar */

/* .subtitile-1{
font-family:"rabar" !important;
}
.font_rabar{
font-family:"rudaw" !important;
} */

.v-list-item {
  margin-bottom: 10px !important;
}

.theme--light .profile_mobile {
  background: rgb(255, 255, 255) !important;
}
.theme--dark .profile_mobile {
  background: #2e3651 !important;
  color: white !important;
}
.theme--dark .profile_mobile .v-list-item__title {
  color: white !important;
}

.bottom-nav {
  display: none;
}
.bottom-nav-item {
  display: none;
}

@media only screen and (max-width: 850px) {
  .bottom-nav {
    display: block;
  }
  .bottom-nav-item {
    display: block;
  }
}
</style>
