<template>
    <div>
        <v-container>
            
<v-row class="d-flex justify-center mb-7 mt-10 ">
    <h2 class="color-gold">{{langkeyword('SIGNUP')}}</h2>
 </v-row>   

<v-row class="d-flex justify-center">
    

        <v-col
          cols="12"
          lg="9"
          md="9"
        >

       
            <div class="d-flex flex-wrap justify-space-between align-center bg_mode pa-13 rounded-lg" >

                <v-col cols="11" lg="3" md="3" class="text-center">

                <Center>
                <span class="orange--text rabar-font" color="primary">{{
                  error_message
                }}</span>
                <span class="teal--text rabar-font" color="primary">{{
                  success_message
                }}</span>
              </Center>
                <v-img  min-width="300" class="mx-auto" src="../../../assets/img/register.svg"></v-img>
       
            </v-col>

            
                <v-col cols="12" lg="5" md="6">
                    
                    <v-row align="center" ali class="d-flex justify-center ">
                        <v-text-field
                                :label="this.langkeyword('NAME')"
                                filled
                                rounded
                                dense
                                v-model="name"
                                append-icon="mdi-text"
                                icon
                            ></v-text-field>
                    </v-row>
                    <v-row align="center" ali class="d-flex justify-center ">
                        <v-text-field
                                :label="this.langkeyword('EMAIL')"
                                filled
                                rounded
                                dense
                                v-model="email"
                                append-icon="mdi-email"
                                icon
                            ></v-text-field>
                    </v-row>
                    <v-row align="center" ali class="d-flex justify-center mt-4">

                        <v-text-field   filled
                                rounded class="mb-14 rabar-font" v-model="password"
                                    :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'" :label="langkeyword('PASSWORD')"
                                    name="password" :type="show ? 'input' : 'password'" hide-details="auto"
                                    @click:append="show = !show" ref="pass" />
                    </v-row>


                    <v-row align="center" ali class="d-flex justify-center ">

                        <v-btn @click="register" small color="primary">{{langkeyword('SIGNUP')}}</v-btn>
                        
                    </v-row>

                    
                   <v-row align="center" ali class="d-flex justify-center mt-6">
                    <v-btn class="text-forget rabar-font letter-spacing-0" 
                                    color="orange" text @click="$router.push('/login')">
                                    {{langkeyword('ALREADYHAVE_AN_ACCOUNT')}} 
                                </v-btn>
                   </v-row>

                         
                </v-col>
  
    
            </div>
      
        </v-col>
      </v-row>

        </v-container>
    </div>
</template>

<script>
import registerRequests from "../../../requests/client/auth/Register";
    export default {
        data() {
            return {
                show:false,
                name:'',
                email:'',
                password:'',
                error_message:'',
                success_message:''
            }
        },

        mounted(){
    this.ifAuthorized()
  },
  methods: {
    ifAuthorized() {
      registerRequests.ifAuthorized(
        {
          isAuthCheck: true,
          account_id: this.clientAuthId(),
          token: this.clientAuthToken(),
        },
        (res) => {
          if (res.data.status === true) {
            this.$router.push('/MyProfile')
          }
        }
      );
    },
    register() {
      registerRequests.createAccount(
        {
          isRegister: true,
          name: this.name,
          email: this.email,
          pass: this.password,
        },
        (res) => {
          if (res.data.status === false) {
            this.error_message = this.langkeyword(res.data.msg);
            this.success_message=''
          } else if (res.data.status === true) {
            this.success_message = this.langkeyword(res.data.msg);
            this.error_message=''
            this.name=''
            this.email=''
            this.password=''
          }
        }
      );
    },
  },

    }
</script>

<style lang="scss" scoped>

</style>