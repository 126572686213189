<template>
    <div>


<v-container>
    <v-carousel
    hide-delimiter-background
        class="slider"
        width="100%"
      > 
    
      <v-carousel-item v-for="(item, index) in this.$store.state.sliders" :key="index"
          reverse-transition="fade-transition"
          transition="fade-transition"
          cycle="cycle"
          style="object-fit:contain !important;"
          class="m-auto mx-auto text-center"
          >
          <img :src="uploadPath('/sliders/'+item.avatar)" width="100%" height="100%" style="object-fit:contain !important;" class="mx-auto m-auto rounded">
      </v-carousel-item>
    
    
      </v-carousel>
</v-container>


    </div>
</template>

<script>
    export default {
        data() {
            return {
               
            }
        },
    } 
</script>

<style lang="scss" scoped>

</style>