<template>
  <div>
    <v-container>
        <v-row
        class="d-flex justify-center mt-16 bg_mode pa-3"
        style="border-left: 2px solid #2c6c70; border-right: 2px solid #2c6c70"
      >
        <h1 class="color-main">{{ langkeyword("ABOUT_US") }}</h1>
      </v-row>
      </v-container>

    <v-row class="p-2  text-center d-flex justify-center mt-16">
      <p class="text-center col-11">
        <span v-if="lang() === 'ku'">{{
          this.$store.state.setting.ku_about
        }}</span>
        <span v-if="lang() === 'ar'">{{
          this.$store.state.setting.ar_about
        }}</span>
        <span v-if="lang() === 'en'">{{
          this.$store.state.setting.en_about
        }}</span>
      </p>
    </v-row>





<div class="row">
<div class="mapouter">
<div class="gmap_canvas">
<iframe class="gmap_iframe" width="100%" 
allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
frameborder="0" scrolling="no" 
marginheight="0" marginwidth="0" 
src="https://maps.google.com/maps?width=700&amp;
height=400&amp;
hl=en&amp;
q=Pasha Pharmacy:تەلاری پزیشکی پاشا&amp;
t=&amp;
z=15&amp;
ie=UTF8&amp;
iwloc=B&amp;
output=embed">
</iframe>
</div>
</div>
</div>








    <appFooter />
  </div>
</template>

<script>
import appFooter from "../../layouts/client/footer.vue";
export default {
  components: { appFooter },
};
</script>

<style scoped>
.mapouter{position:relative;text-align:right;width:100%;height:400px;}
.gmap_canvas {overflow:hidden;background:none!important;width:100%;height:400px;}
.gmap_iframe {height:400px!important;}
</style>