<template>
  <div>
    <v-container class="mt-8">
      <div class="row">
        <div
          class="col-md-6 col-sm-10 col-xs-12 col-xl-6 col-lg-6 mx-auto text-center"
        >
          <img
            class="rounded-lg bg_mode"
            height="400px"
            width="100%"
            style="object-fit: contain !important"
            :src="uploadPath('/products/' + info.avatar)"
          />
        </div>

        <div
          class="col-md-6 col-sm-10 col-xs-12 col-xl-6 col-lg-6 mx-auto text-center"
        >
          <v-card class="mx-auto bg_mode"              height="400px"
>
            <v-card-text>
              <p class="text-h4 text--primary">
                <span v-if="lang() === 'ku'">{{ info.ku_name }}</span>
                <span v-if="lang() === 'ar'">{{ info.ar_name }}</span>
                <span v-if="lang() === 'en'">{{ info.en_name }}</span>
              </p>
              <hr class="mt-4 mb-4 bg_main rounded-pill" />
              <v-btn class="bg_main white--text rounded-pill">
                <v-icon>mdi mdi-watermark</v-icon>
                <h3 class="pa-2">
                  {{ langkeyword("BRAND") }} :
                  <span v-if="lang() === 'ku'">{{ info.brands_ku_name }}</span>
                  <span v-if="lang() === 'ar'">{{ info.brands_ar_name }}</span>
                  <span v-if="lang() === 'en'">{{ info.brands_en_name }}</span>
                </h3>
              </v-btn>
              <hr class="mt-4 mb-4 bg_main rounded-pill" />
              <v-btn class="bg_main white--text rounded-pill">
                <v-icon>mdi mdi-clipboard-list</v-icon>
                <h3 class="pa-2">
                  {{ langkeyword("PRODUCTCATEGORY") }} :
                  <span v-if="lang() === 'ku'">{{
                    info.productcategories_ku_name
                  }}</span>
                  <span v-if="lang() === 'ar'">{{
                    info.productcategories_ar_name
                  }}</span>
                  <span v-if="lang() === 'en'">{{
                    info.productcategories_en_name
                  }}</span>
                </h3>
              </v-btn>
              <hr class="mt-4 mb-4 bg_main rounded-pill" />
              <v-btn class="bg_main white--text rounded-pill">
                <v-icon>mdi mdi-currency-usd</v-icon>
                <h3 class="pa-2">
                  {{ langkeyword("PRICE") }} :
                  <span>{{ info.sale_price }}</span> $
                </h3>
              </v-btn>
            </v-card-text>
          </v-card>
        </div>
      </div>

      <v-row v-if="productgalaries != ''">
        <v-col cols="12" lg="6" md="6" class="mx-auto">
          <v-carousel hide-delimiters>
            <v-carousel-item
              v-for="(item, index) in productgalaries"
              :key="index"
              :src="uploadPath('/productgalaries/' + item.galary)"
            ></v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>

      <v-row class="mt-3">
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
          class="mx-auto text-center"
        >
        <v-tabs class="bg_mode rounded">
            <v-tab class="bg_mode">{{ langkeyword("DETAIL") }}</v-tab>
            <v-tab-item class="bg_mode rounded-br-lg rounded-bl-lg">
              <p class="pa-10 font-weight-thin bg_mode">
                <span v-if="lang() === 'ku'">{{ info.ku_detail }}</span>
                <span v-if="lang() === 'ar'">{{ info.ar_detail }}</span>
                <span v-if="lang() === 'en'">{{ info.en_detail }}</span>
              </p>
            </v-tab-item>
          </v-tabs>
</v-col>


        <v-col
          cols="12"
          sm="12"
          md="10"
          lg="6"
          xl="6"
          class="mx-auto text-center"
        >
          <v-text-field
            class="bg_mode pa-2 rounded-lg"
            v-if="auth_id != null"
            type="number"
            v-model="selected_quantity"
            min="1"
            value="1"
            :prefix="langkeyword('QUANTITY')"
          ></v-text-field>

          <center>
            <v-btn
              v-if="auth_id != null"
              class="bg_mode color_mode text-center mt-2 white--text rounded-xl mx-auto"
              style="border-radius: 5px"
              tile
              large
              dense
              @click="addToCart()"
              ><v-icon>mdi-cart</v-icon> {{ langkeyword("ADD_TO_CART") }}</v-btn
            >
          </center>
        </v-col>
      </v-row>

      <v-row class="alert mt-4">
        <v-col
          class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
          v-if="error_msg != null"
        >
          <v-alert dense type="warning">{{ error_msg }}</v-alert>
        </v-col>
        <v-col
          class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
          v-if="success_msg != null"
        >
          <v-alert dense type="success">{{ success_msg }}</v-alert>
        </v-col>
      </v-row>

      <hr class="mt-5 mb-5" />
    </v-container>

    <v-container class="mt-6 mb-10">
      <v-row
        class="d-flex justify-space-between"
        align="center"
        style="align-items: center !important"
      >
        <span class="mx-2">{{ langkeyword("RELATED_PRODUCTS") }}</span>
        <v-btn outlined class="rounded-xl text-capitalize">{{
          langkeyword("SHOW_MORE")
        }}</v-btn>
      </v-row>

      <v-container class="col-11 px-lg-10 mt-6">
        <v-row class="d-flex justify-center">
          <v-col
            v-for="(item, index) in products"
            :key="index"
            cols="6"
            lg="2"
            sm="4"
            md="3"
          >
            <router-link
              :to="'/products/' + item.id"
              style="text-decoration: none"
              class="color_mode"
            >
              <v-card
                outlined
                shaped
                elevation="5"
                class="mx-auto bg_mode text-center"
                max-width="400"
              >
                <v-img
                  class="white--text mx-auto"
                  width="100%"
                  height="200"
                  :src="uploadPath('/products/' + item.avatar)"
                >
                </v-img>
                <h2
                  class="pb-0 px-2 mt-4 text-truncate"
                  style="font-size: 20px; font-weight: 700 !important"
                >
                  <span v-if="lang() === 'ku'">{{ item.ku_name }}</span>
                  <span v-if="lang() === 'ar'">{{ item.ar_name }}</span>
                  <span v-if="lang() === 'en'">{{ item.en_name }}</span>
                </h2>
                <v-card-subtitle class="pb-0" style="font-size: 16px">
                  <span v-if="lang() === 'ku'">{{
                    item.productcategories_ku_name
                  }}</span>
                  <span v-if="lang() === 'ar'">{{
                    item.productcategories_ar_name
                  }}</span>
                  <span v-if="lang() === 'en'">{{
                    item.productcategories_en_name
                  }}</span>
                </v-card-subtitle>

                <v-card-actions>
                  <v-btn
                    :to="'/products/' + item.id"
                    text
                    class="mx-auto mb-3 mt-2 text-capitalize"
                    small
                  >
                    <v-icon class="mx-1" color="#2c6c70"
                      >mdi mdi-chevron-right-circle</v-icon
                    ></v-btn
                  >

                  <div class="mb-2"></div>
                </v-card-actions>
              </v-card>
            </router-link>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <appFooter />
  </div>
</template>

 
  <script>
// import { VueAgile } from "vue-agile";
import axios from "axios";
import appFooter from "../../layouts/client/footer.vue";

export default {
  components: {
    appFooter,
  },
  data() {
    return {
      auth_id: this.$store.getters.getclientauth.id,
      info: {},
      active_image: "",
      selected_quantity: 1,
      error_msg: null,
      success_msg: null,
      resp: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],

      resp2: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 3,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
      ],

      asNavFor1: [],
      asNavFor2: [],
    };
  },

  mounted() {
    axios
      .post("/app/client/products/getone", {
        getOneProducts: true,
        product_id: this.$route.params.id,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status == true) {
          this.info = res.data.data;
        } else {
          console.log(res.data);
          this.$router.push("/404");
        }
      })
      .catch((err) => {
        console.log("response error " + err);
      });

    this.asNavFor1.push(this.$refs.thumbnails);
    this.asNavFor2.push(this.$refs.main);
  },
  computed: {
    products() {
      return this.$store.state.products
        .filter((entry) => {
          return (
            entry.productcategory_id == this.info.productcategory_id &&
            entry.id != this.info.id
          );
        })
        .slice(0, 6);
    },
    productgalaries() {
      return this.$store.state.productgalaries.filter((entry) => {
        return entry.product_id == this.$route.params.id;
      });
    },
  },
  methods: {
    select_image(select_image) {
      this.active_image = select_image.avatar;
    },
    cleanMessages() {
      setTimeout(() => {
        this.success_msg = null;
        this.error_msg = null;
      }, 3000);
    },
    addToCart() {
      axios
        .post("/app/client/cart/add", {
          addCart: true,
          account_id: this.auth_id,
          product_id: this.$route.params.id,
          qty: this.selected_quantity,
        })
        .then((res) => {
          if (res.data.status === false) {
            this.success_msg = null;
            this.error_msg = this.langkeyword(res.data.msg);
          } else {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
          }

          this.cleanMessages();
        })
        .catch((err) => {
          console.log("response error " + err);
        });
    },
  },
};
</script>
  
  
  <style>
* {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
}
</style>
  